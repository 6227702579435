import { Carousel } from 'antd';
import 'antd/es/carousel/style/index.css';
import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function Home() {
  const partners = [
    { name: '浙江省疾病预防控制中心', img: require('../assets/a22f83.png') },
    { name: '浙江大学未来医学研究中心', img: require('../assets/ff57ac.png') },
    { name: '温州医科大学附属眼视光医院浙江省眼科医院', img: require('../assets/0a6080.png') },
    { name: '安徽省儿童青少年近视防控中心', img: require('../assets/c22371.png') },
    { name: '江西省儿童青少年近视防控中心', img: require('../assets/a32534.png') },
    { name: '贵州省疾控预防控制中心', img: require('../assets/316c33.png') },
    { name: '中国校园健康行动护眼亮眼工程', img: require('../assets/07810a.png') },
    { name: '浙江省义乌卫生健康局', img: require('../assets/54cebe.png') },
    { name: '嘉峪关市教育局', img: require('../assets/5b3f29.png') },
    { name: '安徽省妇幼保健院', img: require('../assets/5539dd.png') },
    { name: '欧普康视', img: require('../assets/70e6e1.png') },
    { name: '爱尔眼科', img: require('../assets/34aebc.png') },
    { name: '山东鲁南眼科医院', img: require('../assets/daa970.png') },
    { name: '河北医科大学第四医院河北省肿瘤医院', img: require('../assets/6d44b0.png') },
    { name: '河北复明眼科医院集团', img: require('../assets/2b0904.png') },
    { name: '东南眼科', img: require('../assets/c3618b.png') },
  ];

  return (
    <Layout nav="home">
      <SEO title="首页" />
      <Carousel autoplay>
        {['df3107.png', '9f6aba.png'].map((item) => (
          <div key={item}>
            <div className="w-screen" style={{ height: '30vw' }}>
              <img src={require(`../assets/${item}`)} alt="banner" />
            </div>
          </div>
        ))}
      </Carousel>
      <section className="bg-gray-50 py-6">
        <div className="container mx-auto px-4">
          <header className="flex flex-col items-center justify-center">
            <h2 className="text-gray-900 text-xl font-medium">产品体系</h2>
            <div className="w-12 h-1 bg-primary"></div>
          </header>
          <div className="mt-8 grid grid-cols-3 gap-4">
            <Link to="/solutions/?type=monitor" className="col-span-2 transition hover:shadow-xl">
              <h3 className="mb-2 border-l-8 border-primary pl-2 truncate text-xs sm:text-base">
                学生健康状况综合监测评价系统
              </h3>
              <img src={require('../assets/ea59e4.png')} alt="图片" className="rounded-lg" />
            </Link>
            <Link to="/solutions/?type=crm" className="transition hover:shadow-xl">
              <h3 className="mb-2 border-l-8 border-primary pl-2 truncate text-xs sm:text-base">轻派眼健康管理系统</h3>
              <img src={require('../assets/d628aa.png')} alt="图片" className="rounded-lg" />
            </Link>
          </div>
          <div className="mt-8 grid grid-cols-3 gap-4">
            <Link to="/solutions/" className="transition hover:shadow-xl">
              <h3 className="mb-2 border-l-8 border-primary pl-2 truncate text-xs sm:text-base">社区眼检查系统</h3>
              <img src={require('../assets/85ad0e.png')} alt="图片" className="rounded-lg" />
            </Link>
            <Link to="/solutions/" className="transition hover:shadow-xl">
              <h3 className="mb-2 border-l-8 border-primary pl-2 truncate text-xs sm:text-base">
                0-6岁儿童眼保健及视力检查系统
              </h3>
              <img src={require('../assets/2459ff.png')} alt="图片" className="rounded-lg" />
            </Link>
            <Link to="/solutions/" className="transition hover:shadow-xl">
              <h3 className="mb-2 border-l-8 border-primary pl-2 truncate text-xs sm:text-base">5G眼科远程诊疗系统</h3>
              <img src={require('../assets/f6678b.png')} alt="图片" className="rounded-lg" />
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-6">
        <div className="container mx-auto px-4">
          <header className="flex flex-col items-center justify-center">
            <h2 className="text-gray-900 text-xl font-medium">合作伙伴</h2>
            <div className="w-12 h-1 bg-primary"></div>
          </header>
          <div className="mt-8 grid grid-cols-2 sm:grid-cols-4 gap-4">
            {partners.map((item) => (
              <figure key={item.name} className="bg-white rounded transition hover:shadow-xl">
                <img src={item.img} alt="图片" />
              </figure>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}
